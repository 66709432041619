@import "../../../variables";

.Portfolio {
    width: 100%;
    background-image: url("../../../static/images/green2.svg");
    background-size: 100%;

    .container {
        max-width: 1440px;
        padding: 110px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;

        @media only screen and (max-width: 1080px) {
            padding: 110px 75px;
        }

        @media only screen and (max-width: 540px) {
            padding: 150px 50px;
        }

        .list {
            flex-basis: 47.5%;

            @media only screen and (max-width: 1080px) {
                display: none;
            }
        }

        .preview {
            flex-basis: 47.5%;
            position: relative;
            margin-right: 20px;

            @media only screen and (max-width: 1080px) {
                flex-basis: 100%;
                margin-right: 0;
            }

            .images {
                position: relative;

                > img {
                    max-width: 100%;
                    min-width: 548px;
                    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);

                    @media only screen and (max-width: 1080px) {
                        min-width: unset;
                    }

                    &.top {
                        transform: translateY(4px);
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }

                    &.bottom {
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        transition: 0.4s ease-in-out;
                    }
                }

                .chevrons {
                    display: none;

                    @media only screen and (max-width: 1080px) {
                        position: absolute;
                        display: flex;
                        justify-content: space-between;
                        padding: 0 10px;
                        width: 100%;
                        top: 50%;
                    }

                    img {
                        cursor: pointer;
                        width: 100px;

                        @media only screen and (max-width: 768px) {
                            width: 75px;
                        }

                        @media only screen and (max-width: 600px) {
                            width: 50px;
                        }
                    }
                }
            }

            .box {
                width: 100%;
                bottom: 0px;
                padding: 20px 0;

                .title {
                    display: flex;
                    align-items: center;

                    h3 {
                        flex-grow: 1;
                        font-size: 40px;
                        margin-bottom: 0;
                        font-family: "Barlow Black";
                    }

                    a {
                        margin-left: 15px;
                    }

                    img {
                        width: 30px;
                    }
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 10px 0;

                    li {
                        list-style-type: none;
                        border-radius: 12px;
                        margin-right: 5px;
                        padding: 2px 12px;
                        font-size: 14px;

                        &.HTML {
                            background-color: tomato;
                        }

                        &.CSS {
                            background-color: cornflowerblue;
                        }

                        &.Sass {
                            background-color: pink;
                        }

                        &.JavaScript {
                            background-color: gold;
                        }

                        &.Firebase {
                            background-color: orange;
                        }

                        &.React {
                            background-color: skyblue;
                        }

                        &.Node\.js {
                            background-color: greenyellow;
                        }

                        &.Agile {
                            background-color: plum;
                        }

                        &.OOP {
                            background-color: #aaaaaa;
                        }

                        &.APIs {
                            background-color: turquoise;
                        }

                        &.MongoDB {
                            background-color: #68b684;
                        }

                        &.Testing {
                            background-color: $secondary-color;
                            border: 1px solid $font-color;
                        }
                    }
                }
            }
        }
    }
}
