@import "../../../variables";

.SearchBar {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    padding: 0 20px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    font-size: 18px;
}
