@font-face {
    font-family: "Barlow Light";
    src: url("./static/fonts/Barlow-Light.ttf");
}

@font-face {
    font-family: "Barlow";
    src: url("./static/fonts/Barlow-Regular.ttf");
}

@font-face {
    font-family: "Barlow Italic";
    src: url("./static/fonts/Barlow-Italic.ttf");
}

@font-face {
    font-family: "Barlow Bold";
    src: url("./static/fonts/Barlow-Bold.ttf");
}

@font-face {
    font-family: "Barlow Black";
    src: url("./static/fonts/Barlow-Black.ttf");
}
