@import "../../../variables";

.Footer {
    width: 100%;
    background-color: #ac8b5a;
    position: relative;
    overflow: hidden;

    .container {
        max-width: 1440px;
        padding: 110px;
        display: flex;
        margin: 0 auto;

        @media only screen and (max-width: 1080px) {
            padding: 110px 75px;
        }

        @media only screen and (max-width: 540px) {
            padding: 110px 50px;
        }

        .content {
            width: 100%;
            display: flex;

            .image {
                flex-shrink: 0;
                width: 250px;
                margin-right: 40px;
                cursor: pointer;

                @media only screen and (max-width: 768px) {
                    display: none;
                }

                @media only screen and (max-width: 1080px) {
                    width: 200px;
                    margin-right: 25px;
                }

                img {
                    width: 100%;
                    border-radius: 50%;
                    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
                }
            }

            .information {
                max-width: 548px;

                hr {
                    margin: 10px 0;
                    border: none;
                    border-top: 1px solid #27303660;
                    height: 1px;
                }

                .social {
                    div {
                        margin-right: 20px;
                        display: inline-block;
                        text-align: center;

                        p {
                            font-size: 12px;
                        }

                        img {
                            max-width: 40px;
                        }
                    }
                }
            }
        }
    }

    > img {
        position: absolute;

        &.chest {
            width: 125px;
            left: 68%;
            top: 7%;
            transform: rotate(20deg);

            @media only screen and (max-width: 1080px) {
                left: 80%;
                top: 2%;
            }
        }

        &.dinosaur {
            width: 250px;
            left: 75%;
            top: 65%;
            transform: rotate(-30deg);

            @media only screen and (max-width: 1080px) {
                left: 65%;
                top: 67%;
            }

            @media only screen and (max-width: 540px) {
                width: 150px;
                top: 70%;
            }
        }
    }
}
